import { ref, computed, watch } from 'vue';
import type { Ref, ComputedRef } from 'vue';
import { useElementSize, unrefElement } from '@vueuse/core';
import { useFloating, offset, flip, size, autoUpdate, shift } from '@floating-ui/vue';
import type { Middleware } from '@floating-ui/vue';
import { toString } from 'lodash-es';
import { pxOrValue, getClosestFocusable } from '../../utils';
import { useZIndex } from '../use-z-index';
import { useFocusScope } from '../use-focus-scope';
import { useId } from '../use-id';

interface UseDropdownReturn {
  active: Ref<boolean>;
  containerRef: Ref<HTMLElement>;
  dropdownRef: Ref<HTMLElement>;
  focused: ComputedRef<boolean>;
  moveFocusOut: (previous?: boolean) => boolean;
  onPositioned: (handler: () => void) => void;
  placement: ComputedRef<string>;
  styles: ComputedRef<Partial<CSSStyleDeclaration>>;
  visible: Ref<boolean>;
  zIndex: ComputedRef<number | undefined>;
  id: string;
}

const DROPDOWN_MAX_HEIGHT = 320;

export function useDropdown(): UseDropdownReturn {
  const containerRef = ref();
  const dropdownRef = ref();

  const { id: hostZoneId, active: focused } = useFocusScope(containerRef);
  useFocusScope(dropdownRef, { parentScopeId: hostZoneId });

  const { width: containerWidth } = useElementSize(containerRef, undefined, {
    box: 'border-box',
  });

  const active = ref(false);
  const visible = ref(false); // We need it to keep element in zIndex stack until it's visible while leave transition

  const { zIndex } = useZIndex({
    active: visible,
  });

  const floatingMiddleware = computed<Middleware[]>(() => {
    const result = [
      size({
        apply({ elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            width: pxOrValue(containerWidth.value),
            maxHeight: pxOrValue(Math.min(availableHeight, DROPDOWN_MAX_HEIGHT)),
          });
        },
      }),
      offset(() => 4),
      flip(),
      shift(),
    ];

    return result;
  });

  const {
    placement: floatingPlacement,
    isPositioned,
    floatingStyles,
  } = useFloating(containerRef, dropdownRef, {
    placement: 'bottom-start',
    middleware: floatingMiddleware,
    whileElementsMounted: autoUpdate,
    open: active,
    transform: false,
  });

  let handlers: Array<() => void> = [];

  watch(isPositioned, (value) => {
    if (value) {
      handlers.forEach((handler) => handler());
      return;
    }

    handlers = [];
  });

  function onPositioned(handler: () => void) {
    if (isPositioned.value) {
      handler();
      return;
    }

    handlers.push(handler);
  }

  const styles = computed<Partial<CSSStyleDeclaration>>(() => ({
    ...floatingStyles.value,
    zIndex: toString(zIndex.value ?? ''),
  }));

  const placement = computed(() => floatingPlacement.value.split('-')[0]);

  function moveFocusOut(previous = false): boolean {
    const containerElement = unrefElement(containerRef);
    const dropdownElement = unrefElement(dropdownRef);

    if (!containerElement || !dropdownElement) {
      return false;
    }

    let target = containerElement;
    let done = false;

    do {
      const focusable = getClosestFocusable({
        initial: target,
        root: document.documentElement,
        previous,
      });

      if (
        focusable &&
        focusable !== dropdownElement &&
        focusable !== containerElement &&
        !dropdownElement?.contains(focusable) &&
        !containerElement?.contains(focusable)
      ) {
        focusable.focus();
        done = true;
      } else {
        target = focusable;
      }
    } while (target && !done);

    return done;
  }

  return {
    active,
    containerRef,
    dropdownRef,
    focused: computed(() => focused.value), // TODO: simplify after changing useFocusScope to return ComputedRef
    id: useId(),
    moveFocusOut,
    onPositioned,
    placement,
    styles,
    visible,
    zIndex,
  };
}
